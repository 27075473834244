<template>
  <div class="box">
    <van-nav-bar title="用户中心" left-text="" left-arrow @click-left="back" />
    <div class="content">
      <div class="head">
        <van-image width="50" height="50" class="img" round src="https://fastly.jsdelivr.net/npm/@vant/assets/cat.jpeg">
          <template v-slot:loading>
            <van-loading vertical type="spinner" size="20" />
          </template>
        </van-image>
        <span class="text" @click="jump">{{ username || '请登录' }}</span>
      </div>
      <van-icon name="setting-o" class="icon" @click="jump" />
    </div>
    <van-divider />
    <van-cell title="收获地址管理" is-link />
    <van-cell title="我的订单" is-link />
    <van-cell title="会员权益" is-link />
    <van-cell title="客服中心" is-link />
    <van-cell title="账户注销" is-link />
  </div>
</template>

<script>
import { cache } from '@/utils/storage';

export default {
  nanme: 'Mine',
  data() {
    return {
      username: ''
    };
  },
  created() {
    const { result = [] } = cache.get('userInfo') || {};
    if (result.length > 0) {
      this.username = result[0].username || '';
    }
  },
  methods: {
    // 返回
    back() {
      this.$router.go(-1);
    },
    jump() {
      this.$router.push({ name: 'login' });
    }
  }
};
</script>

<style scoped lang="scss">
.box {
  .content {
    display: flex;
    align-items: center;
    .head {
      padding: 18px 17px;
      display: flex;
      flex: 1;
      align-items: center;
      .img {
        width: 50px;
        height: 50px;
        margin-right: 20px;
      }
      .text {
        font-size: 13px;
        line-height: 50px;
        color: #333;
        font-weight: 500;
      }
    }
    .icon {
      margin-right: 16px;
    }
  }
}
</style>
